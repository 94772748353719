
.product-option {
  --color: #ffffff;
}

ion-select.product {

  margin: 10px;
  width: 90%;
  color: #ffffff;
  --color: #ffffff; 
  --ion-text-color: #ffffff;
  --placeholder-color: #ffffff;
}

ion-select.product::part(label),
ion-select.product::part(placeholder),
ion-select.product::part(text) {
  color: #ffffff;
}

ion-select.product::part(icon) {
  color: #ffffff;
}

ion-select-option.product {

  color: #ffffff;
  --ion-text-color: #ffffff;
}
ion-select-option.product::part(text) {
  color: #ffffff;
}

ion-img.logo {

  width: 30px;
  height: 30px;
  margin-bottom: 20px;
  margin-top: 10px;
}

ion-icon.menu {

  color: white;
  padding-right: 10px;
}

a.menu {

  color: #ffffff;
  white-space: nowrap;
  text-decoration: none;
  --ion-text-color: #ffffff;
}

.bg-transparent {
  background: transparent;
  margin-left: 20px;
  padding-top: 20px;
}

.pointer {

  cursor: pointer;
  --ion-text-color: #ffffff;
}

.hide-mobile {

  display: block;
}

@media screen and (max-width: 800px) {

  .hide-mobile {

    display: none;
  }
}
