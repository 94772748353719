
h1 {

  color: #222222;
  padding-left: 15px;
  padding-bottom: 10px;
}

.content {

  color: #000000;
  background-color: #ffffff;
  min-height:100vh;
  padding: 0px;
  padding-left: 0px;
}

ion-col.content {

  color: #000000;
  background-color: #ffffff;
  min-height:100vh;
  padding: 20px;
}

div.description {

  color: #555555;
  margin: 15px;
  margin-bottom: 25px;
}

div.alert {

  font-weight: bold;
  margin-top: 15px;
}

ion-img.screenshot {

  border: 1px solid #cccccc;
  margin-bottom: 10px;
  width: 70%;
  max-width: 400px;
}

ion-img.datasetIcon {

  width: 50px;
  height: 50px;
  margin: 20px;
}

ion-icon.datasetActiveChip {

  color: white;
  padding-right: 0px;
}

ion-card-title.dataset {

  margin-left: 8px;
  margin-top: 15px;
  margin-bottom: 10px;
}

ion-col.dataset {

  margin-left: 5px;
  margin-bottom: 15px;
}

.fullscreen {

  width: 100%;
  height: 100%;
  height: 100vh;
  min-height: 700px;
  border: 0px;
}

.border_bottom {

  border-bottom: 1px solid #dddddd;
}


.menu {

  --ion-grid-width: 300px;
}

@media screen and (max-width: 800px) {

  .menu {

    --ion-grid-width: 200px;
  }
}
